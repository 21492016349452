import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import logo from "../assets/logo.png";
import btn_scan from "../assets/btn_scan.png";
import bg_1 from "../assets/bg_1.png";

import { useDispatch, useSelector } from "react-redux";
import { getUserData, logout } from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { Scanner } from "@yudiel/react-qr-scanner";
import Api from "../components/api/api";
import { URL_API } from "../components/constants/api";
import ToastProvider from "../hooks/useToastProvider";
import CustomInput from "../components/Input";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [isScan, setIsScan] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textScan, setTextScan] = useState("");
  const [txtSearch, setTxtSearch] = useState("");

  const [dataScan, setDataScan] = useState<any | null>(null);
  const [dataSearch, setDataSearch] = useState<any[]>([]);

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    console.log("data", dataUser);
  }, [dataUser]);

  const handleScan = () => {
    setIsScan(true);
  };

  const onResultScan = (result: any) => {
    console.log(result);
    if (result) {
      if (result[0].format == "qr_code") {
        console.log("Code", result[0].rawValue);
        if (textScan === result[0].rawValue) return;
        setTextScan(result[0].rawValue);
      }
    }
  };

  useEffect(() => {
    if (textScan != "") {
      setIsLoading(true);
      checkCode(textScan);
    }
  }, [textScan]);

  const checkCode = async (code: string) => {
    const params = {
      code_id: code,
      pg_id: dataUser.id,
      congviec_id: dataUser.congviec_id,
    };
    const res: any = await Api.post(URL_API.SCAN, params);
    setIsLoading(false);
    console.log("handleSubmitForm", res, res.data);
    if (res.data.status == false) {
      setDataScan(res.data);
    } else {
      setDataScan(res.data);
    }
  };

  const acceptCode = async (code: string) => {
    setIsLoading(true);
    const params = {
      code_id: code,
      pg_id: dataUser.id,
      congviec_id: dataUser.congviec_id,
    };
    const res: any = await Api.post(URL_API.ACCEPT, params);
    setIsLoading(false);
    if (res.data.status == false) {
      setDataScan(res.data);
    } else {
      ToastProvider({ type: "success", content: "Check-in thành công" });
      handleScanAgain();
    }
  };

  const handleAcceptCode = () => {
    acceptCode(textScan);
  };

  const handleScanAgain = () => {
    setDataScan(null);
    setTextScan("");
    setIsScan(false);
    setDataSearch([]);
    setIsLoading(false);
    setTxtSearch("");
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const handleSearch = () => {
    setIsSearch(true);
  };
  const handleSearchName = () => {
    if (txtSearch == "") {
      ToastProvider({ type: "error", content: "Vui lòng nhập tên cần tìm" });
      return;
    }
    searchName();
  };

  const searchName = async () => {
    setDataSearch([]);
    const params = {
      name: txtSearch,
      pg_id: dataUser.id,
    };
    const res: any = await Api.post(URL_API.SEARCH, params);
    console.log("searchName", res, res.data);
    if (res.data.status == false) {
      ToastProvider({ type: "error", content: res.data.message });
    } else {
      console.log("res.data", res.data.data);
      setDataSearch(res.data.data);
    }
  };

  useEffect(() => {
    console.log("dataSearch", dataSearch);
  }, [dataSearch]);

  const handleCheckInforSearch = (txt: string) => {
    setIsSearch(false);
    setIsLoading(true);
    setTextScan(txt);
  };

  useEffect(() => {
    console.log("dataScan", dataScan);
  }, [dataScan]);
  return (
    <>
      <div
        className={`w-full h-full min-h-screen bg-no-repeat bg-bottom bg-cover`}
        style={{ backgroundImage: `url(${bg_1})` }}
      >
        <div className="flex flex-col justify-center px-5 py-16 mx-auto w-full text-base font-bold max-w-[480px] text-neutral-80">
          <img loading="lazy" src={logo} className="w-full" />
          {dataScan ? (
            <>
              {dataScan.status == false ? (
                <>
                  <div className="mt-20 px-3 py-3 w-full text-xl font-semibold bg-[#0D3C86] backdrop-opacity-60 rounded-2xl border border-solid border-[#0D3C86]">
                    <span className="font-bold flex gap-4 justify-center text-[16px] text-center text-white">
                      {dataScan.message}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="mt-8 flex flex-col px-3 py-8 font-semibold text-sm text-white rounded-2xl"
                    style={{
                      background:
                        "radial-gradient(216.74% 100% at 0% 0%, rgba(62, 216, 209, 0.8) 0%, rgba(20, 62, 115, 0.8) 79.08%)",
                      backdropFilter: "opacity(0.6)",
                    }}
                  >
                    <div className="text-xl font-black uppercase">
                      Thông tin khách
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="flex justify-between items-start mt-4 w-full">
                        <div className="w-[80px]">STT:</div>
                        <div className="flex-1 shrink font-bold basis-0">
                          {dataScan.info.stt}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[80px]">Họ và Tên:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        <div className="flex-1 shrink font-bold basis-0">
                          {dataScan.info.name}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[80px]">Chức vụ:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {dataScan.info.title}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[80px]">Công ty:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {dataScan.info.company}
                      </div>
                    </div>
                    {/* <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[100px]">Email:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {dataScan.info.email}
                      </div>
                    </div> */}
                  </div>
                  {dataScan.info.is_checkin == 1 ? (
                    <>
                      <div className="mt-2 flex flex-col px-6 py-4 font-semibold  text-center text-white bg-[#0D3C86] backdrop-opacity-60 rounded-2xl border border-solid border-[#0D3C86]">
                        <div className="text-xl font-black uppercase">
                          ĐÃ Check-in
                        </div>
                        <div className="mt-4 w-full text-[#F58026]">
                          {dataScan.message}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {dataScan.status == false || dataScan.info.is_checkin == 1 ? (
                <Button
                  isLoading={false}
                  label="ĐÓNG"
                  handleClick={handleScanAgain}
                />
              ) : (
                <Button
                  isLoading={isLoading}
                  label="Xác nhận check-in cho khách"
                  handleClick={handleAcceptCode}
                />
              )}
            </>
          ) : isScan ? (
            <>
              <div className="flex flex-col justify-center p-4 mt-[30px] w-full bg-indigo-50 rounded-2xl border border-solid border-stone-300">
                <div className=" w-full min-h-[300px]">
                  <Scanner onScan={onResultScan} />
                </div>
              </div>
              <Button
                isLoading={false}
                label="Trở Lại"
                handleClick={handleScanAgain}
              />
            </>
          ) : isSearch ? (
            <>
              <div className="font-medium text-white mt-10">Search tên</div>
              <CustomInput
                type="text"
                placeholder="Tên khách"
                value={txtSearch}
                onChange={(e) => setTxtSearch(e.target.value)}
              />
              <Button
                isLoading={false}
                label="Search"
                handleClick={handleSearchName}
              />
              {dataSearch.length > 0 ? (
                dataSearch.map((item: any, i: number) => (
                  <div
                    key={i}
                    className="mt-4 flex flex-col text-sm px-3 py-4 font-semibold text-white bg-[#0D3C86] backdrop-opacity-60 rounded-2xl border border-solid border-[#0D3C86]"
                  >
                    <div className="flex justify-between items-start w-full">
                      <div className="w-[80px]">STT:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {item.stt}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[80px]">Họ và Tên:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {item.name}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[80px]">Chức vụ:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {item.title}
                      </div>
                    </div>
                    <div className="flex justify-between items-start mt-4 w-full">
                      <div className="w-[80px]">Công ty:</div>
                      <div className="flex-1 shrink font-bold basis-0">
                        {item.company}
                      </div>
                    </div>
                    {item.is_checkin == true ? (
                      <Button
                        isLoading={false}
                        isActive={true}
                        label="Đã Check-in"
                        handleClick={() => {}}
                      />
                    ) : (
                      <Button
                        isLoading={false}
                        label="Check-in"
                        handleClick={() => {
                          handleCheckInforSearch(item.code_id);
                        }}
                      />
                    )}
                  </div>
                ))
              ) : (
                <></>
              )}
            </>
          ) : !isLoading ? (
            <>
              <div className="flex z-0 gap-10 justify-center items-center self-center mt-8 w-full text-base font-bold text-white">
                <div
                  onClick={onLogout}
                  className="justify-center items-center self-center p-2 rounded-lg bg-[#0D3C86] backdrop-opacity-80"
                >
                  {dataUser.username} - V1.0
                </div>
              </div>
              <div
                onClick={handleScan}
                className="flex justify-center items-center self-center mt-12 w-[250px]"
              >
                <img
                  loading="lazy"
                  src={btn_scan}
                  className="w-full aspect-square"
                />
              </div>
              <Button
                isLoading={false}
                label="Tìm thông tin"
                handleClick={handleSearch}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
