type Props = {
  label: string;
  handleClick: () => void;
  isLoading?: boolean;
  isActive?: boolean;
};
const Button = ({
  label,
  handleClick,
  isLoading = false,
  isActive = false,
}: Props) => {
  return (
    <>
      {isLoading ? (
        <div
          className="font-bold text-center items-center px-4 py-2 mt-4 text-center text-white bg-gradient-to-r rounded-[10000px]"
          style={{
            backgroundImage:
              "linear-gradient(to right, #F58026, #F58026, #F58026)",
          }}
        >
          Loading...
        </div>
      ) : isActive ? (
        <div
          onClick={handleClick}
          className="font-bold text-center items-center px-4 py-2 mt-4 text-center text-white bg-gradient-to-r rounded-[10000px]"
          style={{
            backgroundImage:
              "linear-gradient(to right, #e43524, #f31d25, #bf1f1d)",
          }}
        >
          {label}
        </div>
      ) : (
        <div
          onClick={handleClick}
          className="font-bold text-center items-center px-4 py-2 mt-4 text-cexnter text-white bg-[#294CBA] bg-gradient-to-r rounded-[10000px] hover:bg-slate-300 cursor-pointer"
        >
          {label}
        </div>
      )}
    </>
  );
};

export default Button;
