import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import ProtectedRouter from "./auth/ProtectedRouter";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import DataContext from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";
import ProtectedLogin from "./auth/ProtectedLogin";
import Login from "./pages/Login";
import Logout from "./pages/logout";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      { element: <Navigate to="/Login" />, index: true },
      {
        path: "/Login",
        element: <ProtectedLogin props={<Login />} type={""} key={""} />,
      },
    ],
  },
  {
    path: "/game/*",
    element: <ProtectedRouter />,
    children: [
      { path: "", element: <Home /> },
      { path: "logout", element: <Logout /> },
    ],
  },
]);

function App() {
  const [user, setUser] = useState(null);
  const [botConnection, setBotConnection] = useState<WebSocket | null>(null);
  const [dataServer, setDataServer] = useState(null);

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        dataServer,
        setDataServer,
      }}
    >
      <div className="font-MyriadPro w-full h-full min-h-screen bg-gradient-custom">
        <RouterProvider router={router} />
      </div>
    </DataContext.Provider>
  );
}

export default App;
