import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import header from "../assets/header.png";
import bg from "../assets/bg_1.png";
import CustomInput from "../components/Input";
import {
  getUserData,
  getUserStatus,
  getUsertErr,
  loginUser,
} from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button";
import ToastProvider from "../hooks/useToastProvider";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dataUser = useSelector(getUserData);
  const status_login = useSelector(getUserStatus);
  const error_login = useSelector(getUsertErr);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    congviec: "",
  });

  useEffect(() => {
    if (dataUser) {
      navigate("/");
    }
  }, [dataUser]);

  useEffect(() => {
    dispatch(loginUser(formData) as any);
  }, []);

  const handleLogin = () => {
    if (formData.username === "" || formData.password === "") {
      ToastProvider({
        type: "error",
        content: "Vui lòng nhập đầy đủ thông tin",
      });
      return;
    }
    setIsLoading(true);
    dispatch(loginUser(formData) as any);
  };

  useEffect(() => {
    if (status_login) {
      ToastProvider({ type: "success", content: "Đăng nhập thành công" });
      navigate("/");
    }
    if (!status_login && error_login != "") {
      setIsLoading(false);
      ToastProvider({
        type: "error",
        content: error_login,
      });
    }
  }, [dispatch, status_login, error_login]);

  return (
    <>
      <div
        className="w-full h-full min-h-screen bg-no-repeat bg-bottom bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="flex flex-col justify-center px-5 py-20 mx-auto w-full text-base font-bold max-w-[480px] text-neutral-800">
          <img loading="lazy" src={logo} className="mt-10 w-full" />
          <div
            className="flex flex-col px-6 py-8 mt-8 w-full rounded-2xl"
            style={{
              background:
                "radial-gradient(216.74% 100% at 0% 0%, rgba(62, 216, 209, 0.8) 0%, rgba(20, 62, 115, 0.8) 79.08%)",
              backdropFilter: "opacity(0.6)",
            }}
          >
            <div className="font-medium text-white">Tên tài khoản</div>
            <CustomInput
              type="text"
              placeholder="ID Đăng nhập"
              value={formData.username}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  username: e.target.value,
                })
              }
            />
            <div className="mt-4 font-medium text-white">Mật khẩu</div>
            <CustomInput
              type="password"
              placeholder="Password"
              value={formData.password}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  password: e.target.value,
                })
              }
            />
            <Button
              isLoading={isLoading}
              label="Đăng nhập"
              handleClick={handleLogin}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
